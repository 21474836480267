<template>
  <div class="total">
    <div class="configure">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="工资" disabled name="first1"> </el-tab-pane>
        <el-tab-pane label="工资条" name="first">
          <div class="work-title bg-white margin-top padding">
            <div>
              <el-button type="primary" size="small" @click="Popup"
                >新增记账</el-button
              >
            </div>
            <div
              class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap"
            >
              <div class="flex aligin-items-center">
                <div @click="openDate">
                  <span>经办日期</span>
                  <i class="el-icon-caret-bottom" />
                  <el-divider direction="vertical"></el-divider>
                </div>
                <el-popover width="150" trigger="click">
                  <el-checkbox-group v-model="accountName">
                    <el-checkbox class="padding-xs" label="报名" />
                    <el-checkbox class="padding-xs" label="账户余额变动" />
                    <el-checkbox class="padding-xs" label="补费" />
                    <el-checkbox class="padding-xs" label="转课" />
                    <el-checkbox class="padding-xs" label="转课" />
                    <el-checkbox class="padding-xs" label="停课" />
                    <el-checkbox class="padding-xs" label="积分" />
                  </el-checkbox-group>
                  <div slot="reference" class="aligin-items-center flex">
                    <span>账户名称</span>
                    <i class="el-icon-caret-bottom" />
                    <el-divider direction="vertical"></el-divider>
                  </div>
                </el-popover>
                <el-popover width="200" trigger="click">
                  <el-input
                    v-model="schoolInput"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group v-model="handlingShool">
                    <el-checkbox class="padding-xs" label="某某校区1" />
                    <el-checkbox class="padding-xs" label="某某校区2" />
                    <el-checkbox class="padding-xs" label="某某校区3" />
                  </el-checkbox-group>
                  <div slot="reference" class="aligin-items-center flex">
                    <span>经办校区</span>
                    <i class="el-icon-caret-bottom" />
                    <el-divider direction="vertical"></el-divider>
                  </div>
                </el-popover>
                <el-popover width="150" trigger="click">
                  <el-checkbox-group v-model="paymentStatus">
                    <el-checkbox class="padding-xs" label="报名" />
                    <el-checkbox class="padding-xs" label="账户余额变动" />
                    <el-checkbox class="padding-xs" label="补费" />
                    <el-checkbox class="padding-xs" label="转课" />
                    <el-checkbox class="padding-xs" label="转课" />
                    <el-checkbox class="padding-xs" label="停课" />
                    <el-checkbox class="padding-xs" label="积分" />
                  </el-checkbox-group>
                  <div slot="reference" class="aligin-items-center flex">
                    <span>到款状态</span>
                    <i class="el-icon-caret-bottom" />
                    <el-divider direction="vertical"></el-divider>
                  </div>
                </el-popover>
                <el-popover width="350" trigger="click">
                  <el-input v-model="classInput" placeholder="" size="mini" />
                  <el-checkbox-group v-model="shouzhiType">
                    <el-checkbox
                      class="padding-xs"
                      label="蜀西路小四1v1(六星级课程)课程 A"
                    />
                    <el-checkbox
                      class="padding-xs"
                      label="蜀西路小四1v1(六星级课程)课程 B"
                    />
                    <el-checkbox
                      class="padding-xs"
                      label="蜀西路小四1v1(六星级课程)课程 C"
                    />
                  </el-checkbox-group>
                  <div slot="reference" class="aligin-items-center flex">
                    <span>收支类型</span>
                    <i class="el-icon-caret-bottom" />
                    <i class="el-icon-link" />
                    <span class="text-grey">收支小类</span>
                    <i class="el-icon-caret-bottom text-grey" />
                    <el-divider direction="vertical"></el-divider>
                  </div>
                </el-popover>
              </div>

              <div class="work-title-search" style="width: 30%">
                <el-select
                  v-model="searchType"
                  style="width: 25%"
                  slot="prepend"
                  size="small"
                  placeholder="请选择"
                >
                  <el-option label="订单号" value="订单号"></el-option>
                  <el-option label="经办人" value="经办人"></el-option>
                </el-select>
                <el-input
                  style="width: 60%"
                  :placeholder="'请输入' + searchType"
                  v-model="stuInput"
                  class="input-with-select"
                  size="small"
                >
                  <el-button slot="append" icon="el-icon-search" />
                </el-input>
              </div>
            </div>

            <div
              class="work-title-status flex margin-top-sm aligin-items-center justify-content-between"
            >
              <div class="flex aligin-items-center">
                <div class="text-gray flex aligin-items-center">
                  <div>经办日期：</div>
                  <!-- 日期组件 -->
                  <el-date-picker
                    ref="datePicker"
                    v-model="handlingDate"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="mini"
                  />
                </div>
              </div>

              <div class="text-blue cursor">清空筛选</div>
            </div>
          </div>
          <div class="flex justify-content-between">
            <div class="margin-bottom flex flex-wrap-wrap">
              <el-button> 批量确认</el-button>
              <el-button> 导出当前结果</el-button>
            </div>
            <el-popover width="270" trigger="click">
              <el-checkbox-group v-model="ColumnsList">
                <el-checkbox
                  class="padding-xs"
                  :label="item.name"
                  v-for="(item, index) of displayColumns"
                  :key="index"
                  @click="clickDisplay(item)"
                />
              </el-checkbox-group>
              <div slot="reference">
                <el-button type="primary">自定义显示例</el-button>
              </div>
            </el-popover>
          </div>
          <div style="margin-top">
            <div class="margin-bottom">
              <span>当前结果：共查询到14条记录</span
              ><span
                v-if="!statisticsShow"
                @click="statisticsShow = true"
                class="margin-left cursor text-blue"
                style="border-bottom: 1px solid"
                >统计>></span
              >
              <span class="margin-left" v-if="statisticsShow"
                >收入：5200.20&nbsp;&nbsp;支出：0.00&nbsp;&nbsp;差额（收入/支出）：5200.20</span
              >
            </div>
            <as-list
              :initdataList="datalist"
              :default-sort="{ prop: 'date', order: 'descending' }"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column prop="banji" label="账户名称"></el-table-column>
              <el-table-column prop="name" label="收支大类"></el-table-column>
              <el-table-column prop="name1" label="收支小类"></el-table-column>
              <el-table-column
                prop="text3"
                sortable
                label="收支金额/元"
              ></el-table-column>
              <el-table-column prop="text5" label="经办校区"></el-table-column>
              <el-table-column
                prop="text4"
                sortable
                label="经办日期"
              ></el-table-column>
              <el-table-column
                v-for="(item, index) in ColumnsList"
                :key="index"
                prop="text4"
                :label="item"
              ></el-table-column>
              <el-table-column prop="text5" label="学员"></el-table-column>
              <el-table-column prop="text6" label="到款状态"></el-table-column>
              <el-table-column prop="text7" label="订单编号"></el-table-column>
              <el-table-column width="70" label="操作">
                <el-popover placement="top-start" trigger="hover">
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" @click="visible = false"
                      >确定</el-button
                    >
                    <el-button size="mini" @click="visible = false"
                      >编辑</el-button
                    >
                  </div>
                  <span class="text-blue cursor" slot="reference"
                    >操作 <i class="el-icon-caret-bottom text-blue"
                  /></span>
                </el-popover>
              </el-table-column>
            </as-list>
          </div>
        </el-tab-pane>
        <el-tab-pane label="绩效管理" name="admin"> </el-tab-pane>
        <el-tab-pane label="绩效设置" name="setting"> </el-tab-pane>
      </el-tabs>
    </div>
    <addBookkeeping
      :Visible="editVisible"
      @closepop="closeEditPop"
    ></addBookkeeping>
  </div>
</template>
<script>
import addBookkeeping from "../compinents/addBookkeeping.vue";
import AsList from "@/components/as-list";
export default {
  components: {
    AsList,
    addBookkeeping,
  },
  data() {
    return {
      statisticsShow: false,
      ColumnsList: [],
      displayColumns: [
        { name: "经办人", value: false },
        { name: "对方账号", value: false },
        { name: "流水编号", value: false },
        { name: "备注", value: false },
      ],
      searchType: "订单号", //搜索类容
      stuInput: "", //搜索框
      handlingDate: "", //经办日期
      accountName: "", //账户名称
      handlingShool: "", //经办校区
      schoolInput: "",
      classInput: "",
      paymentStatus: "", //到款状态
      shouzhiType: "", //收支类型
      activeName: "first",
      datalist: [
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "150",
          text4: "2021-03-17",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "150",
          text4: "2021-03-17",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "150",
          text4: "2021-03-17",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "150",
          text4: "2021-03-12",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "150",
          text4: "2021-03-16",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "200",
          text4: "2021-03-11",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "250",
          text4: "2021-03-17",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "150",
          text4: "2021-03-18",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "450",
          text4: "2021-03-17",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "150",
          text4: "2021-03-17",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "150",
          text4: "2021-03-17",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "150",
          text4: "2021-03-17",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "150",
          text4: "2021-03-17",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "150",
          text4: "2021-03-17",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "150",
          text4: "2021-03-17",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "150",
          text4: "2021-03-17",
          text5: "0",
          text6: "1",
          text7: "0",
        },
      ],
    };
  },
  methods: {
    clickDisplay(item) {},
    Popup() {
         
      this.editVisible = true; 
    //   this.editType = type;
    },
    openDate() {
      this.$refs.datePicker.pickerVisible = true;
    },
  },
};
</script>

<style lang="scss">
.color- {
  color: #8cc5ff;
}
.color-box {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
}
.total {
  width: 100%;
}
.TopLabel {
  widows: 200px;
}
.TopLabel_1 {
  widows: 300px;
}
.configure {
  width: 100%;
  background-color: #fff;

  padding: 30px 10px 10px 10px;
}
</style>