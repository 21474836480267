<template>
  <el-dialog
    title="新增记账"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="close"
    width="40%"
  >
    <div class="myClient-top-10 padding">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="经办校区" prop="shoolName">
          <el-select
            style="width: 100%"
            v-model="ruleForm.shoolName"
            multiple
            placeholder="请选择"
          >
            <el-option label="全部校区" value="1"> </el-option>
            <el-option label="易杜渠校区" value="2"> </el-option>
          </el-select>

          <p v-if="ruleForm.shoolName.length">
            <span class="status-point"></span>
            <span style="font-size: 12px; color: #409eff">
              共选择{{ ruleForm.shoolName.length }}个校区</span
            >
          </p>
        </el-form-item>

        <el-form-item label="经办日期" required>
          <el-col :span="11">
            <el-form-item prop="date1">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="ruleForm.date1"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="类型">
          <el-radio-group v-model="ruleForm.type">
            <el-radio label="收入"></el-radio>
            <el-radio label="支出"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="大类" prop="largeClass">
          <el-select v-model="ruleForm.largeClass" placeholder="请选择大类">
            <el-option label="大类型一" value="shanghai"></el-option>
            <el-option label="大类型二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小类" prop="smallClass">
          <el-select v-model="ruleForm.smallClass" placeholder="请选择小类">
            <el-option label="小类一" value="shanghai"></el-option>
            <el-option label="小类二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账户" prop="account">
          <el-select v-model="ruleForm.account" placeholder="请选择账户">
            <el-option label="账户一" value="shanghai"></el-option>
            <el-option label="账户二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额" prop="money">
          <el-input
            v-model="ruleForm.money"
            clearable
            placeholder="请输入金额"
          />
        </el-form-item>
        <el-form-item label="备注" prop="desc">
          <el-input type="textarea" v-model="ruleForm.desc"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex justify-content-center">
      <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      <el-button class="myClient-left-30" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    Visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateMoney = (rule, value, callback) => { 
      if (value > this.precontractAmount) {
        callback(new Error("计划付款金额需小于合同金额"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      ruleForm: {
        shoolName: [], //经办校区
        largeClass: "", //大类
        smallClass: "", //小类
        account: "", //账户
        date1: new Date(),
        // .toLocaleDateString()
        delivery: false,
        type: "收入",
        resource: "",
        desc: "",//备注
        money: "",//金额
      },
      rules: {
        money: [
          { required: true, trigger: "blur", message: "金额不能为空" },
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请输入正确额格式,可保留两位小数",
          },
          { validator: validateMoney, trigger: "blur" },
        ],
        shoolName: [
          { required: true, message: "请选择经办校区", trigger: "change" },
        ],
        largeClass: [
          { required: true, message: "请选择大类", trigger: "change" },
        ],
        smallClass: [
          { required: true, message: "请选择小类", trigger: "change" },
        ],
        account: [{ required: true, message: "请选择账户", trigger: "change" }],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],

        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
  },
  created() {
    this.dialogVisible = this.Visible;
  },
  methods: {
    close() {
      this.$emit("closepop");
      this.$refs["ruleForm"].resetFields();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else { 
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style>
.text-align {
  text-align: center;
}
.active {
  background-color: #f5f5f5;
}
.height {
  height: 40px;
}
.status-point {
  background-color: #409eff;
  margin-right: 5px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>
